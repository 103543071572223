<template>
	<v-card
		elevation="0"
		:loading="loadingData"
		class="ma-0 pa-0"
		@keyup.enter="saveContactInfo"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getContactInfo" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>

				<v-col cols="auto" class="text-right py-0 mb-1">
					<v-tooltip bottom nudge-bottom="-15">
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon
								size="30"
								:color="hoverSave? 'blue darken-1' : 'blue darken-2'"
								v-on="onToolTip"
								@mouseover="hoverSave = !hoverSave"
								@mouseleave="hoverSave = !hoverSave"
								@click="saveContactInfo()"
							>mdi-content-save</v-icon>
						</template>
						<small>Save</small>
					</v-tooltip>
					
					<v-tooltip bottom nudge-bottom="-15">
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon
								size="30"
								:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
								v-on="onToolTip"
								@mouseover="hoverRefresh = !hoverRefresh"
								@mouseleave="hoverRefresh = !hoverRefresh"
								@click="getContactInfo"
							>mdi-refresh-circle</v-icon>
						</template>
						<small>Refresh</small>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0">
			
			<v-form ref="formContactInfo">	
				<v-simple-table :height="userFormHeight - 40">
					<template v-slot:default>
					
						<v-row dense no-gutters class="pr-2">
						
							<v-col cols="12">
								<v-icon medium class="pb-2" color="blue darken-2">mdi-home-floor-1</v-icon>
								<span class="title">&nbsp;Primary Home Address</span>
							</v-col>
							
							<v-col cols="12" xl="6" lg="6" md="6" sm="12">
								<v-text-field
									label="House No./ Street Name/ Brgy. Name*"
									v-model="main_addr1"
									validate-on-blur
									:rules= "rulesMainAddress"
									@blur="main_addr1 = trimText(main_addr1)"
									class="uppercase-input"
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-autocomplete
									label="City/Municipality*"
									v-model="main_addr2"
									v-if="!newMainCity"
									clearable
									validate-on-blur
									:rules= "rulesMainCity"
									:items="optionMainCity"
									item-text="cities"
									item-value="cities"
									:search-input.sync="searchMainCity"
									:loading="loadingMainCity"
									:no-data-text= "NTDMainCity"
									:allow-overflow=false
									@focus="onMainCity = true"
									@blur="onMainCity = false"
									@change="dropdownProvince('mainCity')"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newMainCity = !newMainCity; oldMainCity = main_addr2; main_addr2 = ''">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-autocomplete>
								
								<v-text-field
									label="City/Municipality*"
									v-model="main_addr2"
									v-if="newMainCity"
									hint="Specify here"
									validate-on-blur
									:rules= "rulesMainCity"
									@blur="main_addr2 = trimText(main_addr2)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newMainCity = !newMainCity; main_addr2 = oldMainCity;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Province*"
									v-model="main_addr3"
									v-if="!newMainProvince"
									ref="mainProvince"
									:rules= "rulesMainProvince"
									validate-on-blur
									:loading="loadingMainProvince"
									:items="optionMainProvince"
									item-text="province"
									item-value="province"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newMainProvince = !newMainProvince; oldMainProvince = main_addr3; main_addr3 = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									label="Province*"
									v-model="main_addr3"
									v-if="newMainProvince"
									hint="Specify here"
									:rules= "rulesMainProvince"
									validate-on-blur
									@blur="main_addr3 = trimText(main_addr3)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newMainProvince = !newMainProvince; main_addr3 = oldMainProvince;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
						
							<v-col cols="12">
								<v-icon medium class="pb-2" color="blue darken-2">mdi-home-floor-2</v-icon>
								<span class="title">&nbsp;Secondary Home Address</span>
							</v-col>
							
							<v-col cols="12" xl="6" lg="6" md="6" sm="12">
								<v-text-field
									label="House No./ Street Name/ Brgy. Name"
									v-model="temp_addr1"
									:rules= "rulesTempAddress"
									validate-on-blur
									@blur="temp_addr1 = trimText(temp_addr1)"
									class="uppercase-input"
								></v-text-field>
							</v-col>
						
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-autocomplete
									label="City/Municipality"
									v-model="temp_addr2"
									v-if="!newTempCity"
									clearable
									:rules="rulesTempCity"
									validate-on-blur
									:items="optionTempCity"
									item-text="cities"
									item-value="cities"
									:search-input.sync="searchTempCity"
									:loading="loadingTempCity"
									:no-data-text= "NTDTempCity"
									:allow-overflow=false
									@focus="onTempCity = true"
									@blur="onTempCity = false"
									@change="dropdownProvince('tempCity')"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newTempCity = !newTempCity; oldTempCity = temp_addr2; temp_addr2 = ''">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-autocomplete>
								
								<v-text-field
									label="City/Municipality"
									v-model="temp_addr2"
									v-if="newTempCity"
									hint="Specify here"
									:rules="rulesTempCity"
									validate-on-blur
									@blur="temp_addr2 = trimText(temp_addr2)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newTempCity = !newTempCity; temp_addr2 = oldTempCity;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Province"
									v-model="temp_addr3"
									ref="tempProvince"
									clearable
									v-if="!newTempProvince"
									:rules="rulesTempProvince"
									:loading="loadingTempProvince"
									:items="optionTempProvince"
									item-text="province"
									item-value="province"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newTempProvince = !newTempProvince; oldTempProvince = temp_addr3; temp_addr3 = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									label="Province"
									v-model="temp_addr3"
									v-if="newTempProvince"
									hint="Specify here"
									:rules="rulesTempProvince"
									validate-on-blur
									@blur="temp_addr3 = trimText(temp_addr3)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newTempProvince = !newTempProvince; temp_addr3 = oldTempProvince;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
						
							<v-col cols="12">
								<v-icon medium class="pb-2" color="blue darken-2">mdi-phone-plus</v-icon>
								<span class="title">&nbsp;Contact Details</span>
							</v-col>
							
							<v-col cols="12" xl="6" lg="6" md="6" sm="12">
								<v-text-field
									label="Email Address*"
									v-model="email"
									:rules= "rulesEmail"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="6" lg="6" md="6" sm="12">
								<v-text-field
									label="Telephone Number"
									v-model="telephone"
									v-mask="maskTelephone"
									:error="errorTelephone"
									:error-messages="errorMsgTelephone"
									validate-on-blur
									placeholder="00-0000-0000"
									hint="Area code (2) + 8 Digits landline number"
									:rules= "rulesTelephone"
									@keyup="telephone = numberFormat()"
									@blur="telephoneFormat()"
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="6" lg="6" md="6" sm="12">
								<v-text-field
									label="Primary Mobile Number*"
									v-model="mobileNumber"
									v-mask="maskMobile"
									:rules= "rulesMobileMain"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="6" lg="6" md="6" sm="12">
								<v-text-field
									label="Secondary Mobile Number"
									v-model="mobileNumberSecondary"
									v-mask="maskMobile"
									:rules= "rulesMobileSecondary"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12"><small>Note: Field with asterisk(*) is required.</small></v-col>
							
						</v-row>
					</template>					
				</v-simple-table>
			</v-form>
			
			<v-dialog
				v-model="loadingUpdate"
				max-width="355"
				persistent
			>
				<v-card color="primary" dark>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
		</v-card-text>
	</v-card>
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutData 	= null;
var timeoutMainCity = null;
var timeoutTempCity = null;

export default {
	directives: {mask},
	data () {
		return {
			hoverRefresh		: false,
			hoverSave			: false,
			
			newMainCity			: false,
			newMainProvince		: false,
			newTempCity			: false,
			newTempProvince		: false,
			
			oldMainCity			: '',
			oldMainProvince		: '',
			oldTempCity			: '',
			oldTempProvince		: '',
			
			NTDMainCity			: 'Start typing to search',
			NTDTempCity			: 'Start typing to search',
			
			//loading status
			isLoaded			: false,
			loadingData			: false,
			loadingMainCity		: false,
			loadingTempCity		: false,
			loadingMainProvince	: false,
			loadingTempProvince	: false,
			loadingUpdate		: false,
			
			//data
			main_addr1			: '',
			main_addr2			: '',
			main_addr3			: '',
			temp_addr1			: '',
			temp_addr2			: '',
			temp_addr3			: '',
			email				: '',
			telephone	 		: '',
			mobileNumber		: '',
			mobileNumberSecondary: '',
			
			//mask
			maskMobile			: '####-####-###',
			maskTelephone		: '##-####-####',
			
			//autocomplete
			searchMainCity		: null,
			searchTempCity		: null,
			
			//dropdown option
			optionMainCity		: [],
			optionTempCity		: [],
			optionMainProvince	: [],
			optionTempProvince	: [],
			
			//secondary address validation
			errorTelephone		: false,
			errorMsgTelephone	: '',
			
			//rules
			rulesMainAddress: [
				v => !!v || 'This field is required',
				v => (v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-Z0-9ñÑ.,/#-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesMainCity: [
				v => !!v || 'This field is required',
				v => (v && this.stringLength(v) >= 3) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesMainProvince: [
				v => !!v || 'This field is required',
				v => (v && this.stringLength(v) >= 3) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesTempAddress: [
				v => this.validTempAddress(v) || 'This field must not be empty',
				v => !/[^a-zA-Z0-9ñÑ.,/#-\s]+/.test(v) || 'Must be alpha characters only',
				v => ((v && this.stringLength(v) >= 5) || (this.stringLength(v) == 0)) || 'Must be atleast 5 characters',
				v => ((v && this.stringLength(v) <= 100) || (this.stringLength(v) == 0)) || 'Must be less than 100 characters',
			],
			rulesTempCity: [
				v => this.validTempAddress(v) || 'This field must not be empty',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
				v => ((v && this.stringLength(v) >= 3) || (this.stringLength(v) == 0)) || 'Must be atleast 3 characters',
				v => ((v && this.stringLength(v) <= 50) || (this.stringLength(v) == 0)) || 'Must be less than 50 characters',
			],
			rulesTempProvince: [
				(v) => this.validTempAddress(v) || 'This field must not be empty',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
				v => ((v && this.stringLength(v) >= 3) || (this.stringLength(v) == 0)) || 'Must be atleast 3 characters',
				v => ((v && this.stringLength(v) <= 50) || (this.stringLength(v) == 0)) || 'Must be less than 50 characters',
			],
			rulesEmail: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'Invalid email',
			],
			rulesMobileMain: [
				v => !!v || "Mobile number is required",
				v => /[0][9][0-9]{2}[-][0-9]{4}[-][0-9]{3}$/.test(v) || 'Invalid mobile number',
			],
			rulesMobileSecondary: [
				v => (!v || /[0][9][0-9]{2}[-][0-9]{4}[-][0-9]{3}$/.test(v)) || 'Invalid mobile number',
			],
			rulesTelephone: [
				v => (!v || /[0-9]{2}[-][0-9]{4}[-][0-9]{4}$/.test(v)) || 'Invalid telephone number',
			],
		}
	},
	methods: {
		getContactInfo () {
			if(this.loadingData && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();
			
			this.$store.dispatch(CLEAR_ALERT);
			this.loadingData = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
			
			this.$http.get('contact', { cancelToken: source.token })
			.then((resp) => {
				this.main_addr1 = resp.data.MAIN_ADDR1; //stree
				this.main_addr2 = resp.data.MAIN_ADDR2; // city
				this.main_addr3 = resp.data.MAIN_ADDR3; //province
				this.temp_addr1 = resp.data.SECONDARY_ADDR1; //stree
				this.temp_addr2 = resp.data.SECONDARY_ADDR2; // city
				this.temp_addr3 = resp.data.SECONDARY_ADDR3; //province
				this.email 		= resp.data.EMAIL;
				
				if(resp.data.TELEPHONE != null){
					const mobile = resp.data.MOBILE.replace(/[^\d]/g, "");
					resp.data.MOBILE = (mobile != '')? mobile.substr(0, 4)+'-'+mobile.substr(4, 4)+'-'+mobile.substr(8, 3) : '';
					
					this.mobileNumber = resp.data.MOBILE;
				}
				
				if(resp.data.TELEPHONE != null){
					const mobile2 = resp.data.SECONDARY_MOBILE.replace(/[^\d]/g, "");
					resp.data.SECONDARY_MOBILE = (mobile2 != '')? mobile2.substr(0, 4)+'-'+mobile2.substr(4, 4)+'-'+mobile2.substr(8, 3) : '';
					
					this.mobileNumberSecondary = resp.data.SECONDARY_MOBILE;
				}
				
				if(resp.data.TELEPHONE != null){
					const telephone = resp.data.TELEPHONE.replace(/[^\d]/g, "");
					resp.data.TELEPHONE = (telephone != '')? telephone.substr(0, 2)+'-'+telephone.substr(2, 4)+'-'+telephone.substr(6, 4) : '';
					
					this.telephone 	= resp.data.TELEPHONE;
				}
			
				if(this.main_addr2 != '')this.optionMainCity.push({ cities: this.main_addr2 });
				if(this.main_addr3 != '')this.optionMainProvince.push({ province: this.main_addr3 });
				
				if(this.temp_addr2 != '')this.optionTempCity.push({ cities: this.temp_addr2 });
				if(this.temp_addr3 != '')this.optionTempProvince.push({ province: this.temp_addr3 });
				
				this.telephoneFormat();
				
				this.isLoaded = true;
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					
					this.getContactInfo();
				}, 60000);
			})
			.finally(() => {
				this.loadingData = false;
			});
		},
		saveContactInfo(){
			if(this.$refs.formContactInfo.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.loadingUpdate = true;
				
				this.$http.put('contact', {
					newMainCity		: this.newMainCity,
					newMainProvince	: this.newMainProvince,
					newTempCity		: this.newTempCity,
					newTempProvince	: this.newTempProvince,
					
					MAIN_ADDR1		: this.main_addr1.toUpperCase().trim(),
					MAIN_ADDR2		: this.main_addr2.toUpperCase().trim(),
					MAIN_ADDR3		: this.main_addr3.toUpperCase().trim(),
					SECONDARY_ADDR1	: (this.temp_addr1)? this.temp_addr1.toUpperCase().trim() : '',
					SECONDARY_ADDR2	: (this.temp_addr2)? this.temp_addr2.toUpperCase().trim() : '',
					SECONDARY_ADDR3	: (this.temp_addr3)? this.temp_addr3.toUpperCase().trim() : '',
					EMAIL			: this.email,
					TELEPHONE		: this.telephone,
					MOBILE			: this.mobileNumber,
					SECONDARY_MOBILE: this.mobileNumberSecondary
				})
				.finally(() => {
					this.loadingUpdate = false;
				});
			}
		},
		dropdownProvince(field){
			
			var search = ( field == 'mainCity' )? this.main_addr2 : this.temp_addr2;
			( field == 'mainCity' )? this.loadingMainProvince = true : this.loadingTempProvince = true;
			
			this.$http.get('dropdown/province', {
				params: {
					search: search
				}
			})
			.then((resp) => {
			
				if( field == 'mainCity' ){
					if(resp.data.length > 0)this.newMainProvince = false;
					this.optionMainProvince = resp.data;
					this.main_addr3 = (resp.data.length == 1)? resp.data[0].province : '';
				}else{
					if(resp.data.length > 0)this.newTempProvince = false;
					this.optionTempProvince = resp.data;
					this.temp_addr3 = (resp.data.length == 1)? resp.data[0].province : '';
				}
			})
			.finally(() => {
				this.loadingMainProvince = false;
				this.loadingTempProvince = false;
			});
		},
		numberFormat(){
			return this.telephone.replace(/[^0-9-]+/g, '').replace('--', '-');
		},
		telephoneFormat(){
			const tel = this.telephone.replace(/-/gi, '').trim();
			if(tel.length == 7){
				this.telephone = tel.slice(0, 3)+'-'+tel.slice(3, 7);
			}else if(tel.length == 8){
				this.telephone = tel.slice(0, 4)+'-'+tel.slice(4, 8);
			}else if(tel.length == 10){
				this.telephone = tel.slice(0, 2)+'-'+tel.slice(2, 6)+'-'+tel.slice(6, 10);
			}
			
			if( tel.length == 0 || tel.length == 7 || tel.length == 8 || tel.length == 10 ){
				this.errorTelephone = false;
				this.errorMsgTelephone = '';
			}else{
				this.errorTelephone = true;
				this.errorMsgTelephone = 'Invalid telephone number';
			}
		},
		validTempAddress(val){
			if( this.temp_addr1 || this.temp_addr2 || this.temp_addr3){
				return (!val)? false : true;
			}else{
				return true;
			}
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		trimText(text){
			return text.toUpperCase().replace('ñ', 'Ñ').replace(/ +/g, ' ').trimStart().trim();
		},
	},
	watch: {
		searchMainCity (val) {
			if(!this.onMainCity) return
			
			clearTimeout(timeoutMainCity);
			
			timeoutMainCity = setTimeout(() => {
				this.loadingMainCity = true;
				this.NTDMainCity = "Searching..."
				this.$http.get('autocomplete/cities', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.optionMainCity = res.data;
					if(res.data.length == 1 && typeof this.$refs.mainProvince !== 'undefined')this.$refs.mainProvince.validate();
				}).finally(() => {
					this.loadingMainCity = false;
					this.NTDMainCity = (this.optionMainCity.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		searchTempCity (val) {
			if(!this.onTempCity) return
			
			clearTimeout(timeoutTempCity);
			
			timeoutTempCity = setTimeout(() => {
				this.loadingTempCity = true;
				this.NTDTempCity = "Searching..."
				this.$http.get('autocomplete/cities', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.optionTempCity = res.data;
					if(res.data.length == 1 && typeof this.$refs.tempProvince !== 'undefined')this.$refs.tempProvince.validate();
				}).finally(() => {
					this.loadingTempCity = false;
					this.NTDTempCity = (this.optionTempCity.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
	},
	computed:{
		...mapGetters(['userFormHeight', 'errorOn', 'errorType', 'errorMessage'])
	},
	created () {
		this.getContactInfo();
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutMainCity);
		clearTimeout(timeoutTempCity);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>